.c-carousel {
  margin-bottom: 0;
  visibility: hidden;
  height: 0;

  &.slick-initialized {
    visibility: visible;
    height: auto;
  }

  .slick-arrow {
    width: 40px;
    height: 40px;
    color: var(--color--grey--lighter);
    overflow: hidden;
    z-index: 3;

    &:not(.slick-disabled) {
      background: var(--color--primary--light);
    }

    .slick-next-icon {
      &:before {
        content: '\f054';
      }
    }

    .slick-prev-icon {
      &:before {
        content: '\f053';
      }
    }
  }

  .slick-prev .slick-prev-icon,
  .slick-next .slick-next-icon {
    color: var(--color--primary--dark);
    font-family: var(--font--awesome);
    font-weight: 300;
  }

  &--alerts {
    padding: 20px 0 16px;
    min-height: 64px;
    @include bp-max-large {
      padding-inline: var(--gutter);
    }
    .slick-arrow {
      top: 50%;
      transform: translateY(-50%);

      .slick-next-icon,
      .slick-prev-icon {
        font-size: 20px;
      }
    }

    .slick-next {
      @include bp-xlarge {
        right: -96px;
      }
    }

    .slick-prev {
      @include bp-xlarge {
        left: auto;
        right: -40px;
      }
    }

    .c-label {
      align-self: flex-start;
      margin: 0;

      @include bp-max-small {
        align-self: center;
      }

      @include bp-small {
        position: relative;
        top: 2px;
      }
    }
  }

  &--menu {
    min-height: 80px;

    @include bp-max-xlarge {
      padding-left: 48px;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      display: block;
      width: 80px;
      height: 100%;
      z-index: 1;
    }

    &:before {
      left: calc(var(--gutter) * (-1));
      background-image: linear-gradient(to left, transparent 0%, #ffffff 50%);

      @include bp-xlarge {
        display: none;
      }
    }

    &:after {
      right: calc(var(--gutter) * (-1));
      background-image: linear-gradient(to right, transparent 0%, #ffffff 50%);

      @include bp-xlarge {
        width: calc((100vw - 100%) / 2 + 200px);
        right: calc((100vw - 100%) / (-2));
        background-image: linear-gradient(
          to right,
          transparent 0%,
          #ffffff 100px
        );
      }
    }

    .slick-slide {
      margin: 0 16px;
    }
    .slick-next {
      right: -16px;
      @include bp-xlarge {
        right: 0;
      }
    }
    .slick-prev {
      left: -16px;
      @include bp-xlarge {
        left: auto;
        right: 48px;
      }
    }
    &__item a {
      position: relative;
      display: flex;
      align-items: center;
      font-size: 18px;
      line-height: 1.3;

      @media (hover) {
        &:hover {
          color: var(--color--primary--hover);
          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 3px;
            background: var(--color--primary);
          }
        }
      }

      &:focus-visible {
        color: var(--color--primary);
      }
    }
  }

  &--products {
    margin-inline: calc(var(--gutter) * (-1));
    padding: calc(var(--gutter) / 2);

    .slick-list {
      overflow: visible;
    }

    .slick-slide {
      margin: calc(var(--gutter) / 2);
    }

    .slick-arrow {
      top: -48px;
      width: 48px;
      height: 48px;

      &:not(.slick-disabled) {
        background: #3b4962;
        border-radius: 24px;
      }
    }

    .slick-next {
      right: 32px;
    }

    .slick-prev {
      left: calc(100% - 136px);
    }
  }

  &--thumbs {
    padding-inline: 48px;
    .slick-slide {
      margin-inline: 8px;
      &:not(.slick-current) {
        img {
          opacity: 0.6;
        }
      }

      button {
        @media (hover) {
          &:hover {
            img {
              opacity: 1;
            }
          }
        }

        &:focus {
          img {
            opacity: 1;
          }
        }

        &:focus-visible {
          outline-offset: 0;
        }
      }
    }

    .slick-next {
      right: 0;
    }
    .slick-prev {
      left: 0;
    }

    .c-btn--img {
      padding: 8px;
      border: 1px solid var(--color--grey);
    }
  }

  &--gallery {
    a {
      &:focus-visible {
        outline-offset: -1px;
      }
    }
  }

  &--popup {
    width: 80vw;
    max-width: 1280px;

    img {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
      opacity: 0;

      .c-overlayer.active & {
        opacity: 1;
        transition: all 0.4s ease-out;
        transition-delay: 0.5s;
      }
    }

    .slick-arrow {
      width: 64px;
      height: 64px;
    }
  }

  .slick-slide {
    height: inherit;

    > div {
      display: flex;
      height: 100%;
    }
  }

  .slick-track {
    display: flex;
  }

  &.c-carousel--products {
    .c-card {
      &__media {
        max-height: 272px;
      }
    }
  }

  &--menu {
    &__item {
      img {
        max-height: 80px;
      }
    }
  }
}
